<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              {{
                $t("certificate.management")
              }}
            </li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span> {{ $t("certificate.management") }} </span>
          </h2>
        </div>
        <div class="section-header-right">
          <button class="btn btn-default" :disabled="isDisabledBtn" @click="bulkDownloadCertifications">
            <i class="uil-folder-download"></i> {{ $t('certificate.export_pdf_btn') }}
          </button>
          <router-link :to="{ name: 'company.certificate.add' }" class="btn btn-default">
            <i class="uil-plus"></i> {{ $t("certificate.add") }}
          </router-link>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <!-- <div>
              <a href="#" class="btn btn-icon btn-hover  btn-circle">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop" uk-drop="mode: hover; pos: right-center; offset: 0">
                <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                    :placeholder="$t('general.search')" autofocus>
                </form>
              </div>
            </div> -->
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                  :placeholder="$t('general.search')" />
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.sort_list')" title=""
                aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('name', 'asc')">
                    <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                  </li>
                  <li @click="sortOrder('name', 'desc')">
                    <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'asc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_asc") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'desc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_desc") }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t("certificate.name") }}</th>
                <th scope="col">{{ $t("general.added_at") }}</th>
                <th scope="col">{{ $t("general.status") }}</th>
                <th scope="col">{{ $t("certificate.renewal_period") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <tr v-for="(item, index) in certificates">
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child" style="width: max-content">
                        <i class="uil-medal"></i>
                        <span class="avatar-child avatar-badge bg-success"></span>
                      </div>
                    </div>
                    <div class="media-body ml-4">
                      <router-link :to="{
                        name: 'company.certificate.edit',
                        params: { id: item.id },
                      }" class="name h6 mb-0 text-sm">
                        {{ item.name }}
                      </router-link>
                    </div>
                  </div>
                </th>
                <td><i class="uil-calendar-alt"></i> {{ item.created_at }}</td>
                <td><i class="uil-check"></i> {{ item.status }}</td>
                <td>{{ item.renewal }}</td>
                <td class="text-right">
                  <!-- Actions -->
                  <div class="actions ml-3">
                    <router-link :to="{
                      name: 'company.certificate.edit',
                      params: { id: item.id },
                    }" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('certificate.edit')">
                      <i class="uil-pen"></i>
                    </router-link>
                    <a class="btn btn-icon btn-hover btn-sm btn-circle" @click="removeItem(item.id)"
                      :uk-tooltip="$t('general.delete')">
                      <i class="uil-trash text-danger"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination v-if="pagination && pagination.data.length" :pagination="pagination" @changePage="setPage($event)">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Pagination from "@/assets/components/Pagination";
import store from "@/core/services";
import moment from "moment";
import module, {
  BASE_URL,
  GET_ITEMS,
  MODULE_NAME,
  ITEMS,
  ERROR,
  DELETE_ITEM,
} from "@/core/services/store/certificate.module";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "CertificatesList",
  components: { Pagination, Swal },
  data() {
    return {
      isDisabledBtn: false,
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    certificates() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      for (let i = 0; i < this.items.data.length; i++) {
        let currentItem = this.items.data[i];
        if (currentItem) {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
            renewal: "Otomatik",
            status:
              currentItem.status_id == true
                ? this.$t("general.active")
                : this.$t("general.passive"),
            created_at: currentItem.created_at
              ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
              : "-",
          });
        }
      }
      return results;
    },
  },
  methods: {
    filterResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: this.filter.search,
          page: this.page,
          per_page: this.per_page,
          order: this.filter.order,
          sort: this.filter.sort,
        },
      });
    },
    removeItem(id) {
      this.$store
        .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
          url: BASE_URL + "/" + id,
        })
        .then((response) => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.filterResult();
          } else this.errorMessages.push(this.error);
        });
    },
    sortOrder(sort, order) {
      let self = this;
      self.page = 1;
      self.filter.order = order;
      self.filter.sort = sort;
      this.filterResult();
    },
    setPage(page) {
      let self = this;
      self.page = page;
      this.filterResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    bulkDownloadCertifications() {
      this.isDisabledBtn = true;
      let self = this;
      axios
          .get(process.env.VUE_APP_BACKEND_APP_URL + "/api/report/export/certificates/pdf", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.secureLocalStorage("token"),
            },
          })
          .then((response) => {
            Swal.fire({
              text: this.$t('certificate.export_pdf'),
              icon: "success",
              confirmButtonText: this.$t('general.ok'),
              confirmButtonColor: "#ae71ff",
            });
          }).finally(() => {
            this.isDisabledBtn = false;
          });
    }
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  mounted() {
    this.filterResult();
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
